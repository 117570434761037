// @file
// The major stylesheet for this theme.
//
// We categorize our components by creating headings in this file. See the
// description on the style guide home page for more information.


// Colors and Sass
//
// Documentation for colors and Sass mixins and variables.
//
// Weight: -1
//
// Style guide: sass
@import 'init';

// Defaults
//
// These are the default base styles applied to HTML elements.
//
// Component classes can override these styles, but if no class applies a style
// to an HTML element, these styles will be the ones displayed.
//
// Style guide: base

// Ensure fonts get loaded first to minimize front-end performance impact.
@import 'base/fonts';
@import 'base/normalize';

// Layouts
//
// The layout styling for major parts of the page that are included with the
// theme. Note: some Panels layouts are included in other parts of the system
// and are not documented.
//
// Style guide: layouts
@import 'layouts/layout-3col/layout-3col';
@import 'layouts/layout-center/layout-center';
@import 'layouts/layout-swap/layout-swap';

// Components
//
// Design components are reusable designs that can be applied using just the CSS
// class names specified in the component.
//
// Weight: 1
//
// Style guide: components

@import 'components/box/box';
@import 'components/clearfix/clearfix';
@import 'components/comment/comment';
@import 'components/divider/divider';
@import 'components/header/header';
@import 'components/hidden/hidden';
@import 'components/highlight-mark/highlight-mark';
@import 'components/inline-links/inline-links';
@import 'components/inline-sibling/inline-sibling';
@import 'components/messages/messages';
@import 'components/print-none/print-none';
@import 'components/responsive-video/responsive-video';
@import 'components/visually-hidden/visually-hidden';
@import 'components/watermark/watermark';
@import 'components/wireframe/wireframe';

// Navigation
//
// Navigation components are specialized design components that are used for
// page navigation.
//
// Weight: 2
//
// Style guide: navigation

@import 'navigation/breadcrumb/breadcrumb';
@import 'navigation/more-link/more-link';
@import 'navigation/nav-menu/nav-menu';
@import 'navigation/navbar/navbar';
@import 'navigation/pager/pager';
@import 'navigation/skip-link/skip-link';
@import 'navigation/tabs/tabs';

// Forms
//
// Form components are specialized design components that are applied to forms
// or form elements.
//
// Weight: 3
//
// Style guide: forms

@import 'forms/autocomplete/autocomplete';
@import 'forms/button/button';
@import 'forms/collapsible-fieldset/collapsible-fieldset';
@import 'forms/form-item/form-item';
@import 'forms/form-table/form-table';
@import 'forms/progress-bar/progress-bar';
@import 'forms/progress-throbber/progress-throbber';
@import 'forms/resizable-textarea/resizable-textarea';
@import 'forms/table-drag/table-drag';





/* --------------------------------------------------------------------------------------- */
/* ---------- Custom styles for the Plain Communities Business Exchange site. ------------ */
/* ----------------------------------- Mobile first -------------------------------------- */
/* ----------------- Original site build by CTY from OTS - July 2017 --------------------- */

/* --- VARIABLES --- */
$tab: 44em;
$desk: 70em;
$max-content-width: 1100px;
$content-spacing: 4%;
$light-gray: #ededed;
$half-light-gray: #d9d9d9;
$gray: #636363;
$dark-gray: #444;
$light-black: #333;
$red: #c41230;



// GENERAL STYLES
body {
	font-family: Helvetica, Verdana, Tahoma, 'DejaVu Sans', sans-serif;
	color: #000;
	line-height: 1.7em;

	a,
	a:hover,
	a:active,
	a:visited {
		color: $red;
		text-decoration: none;
	}

	a.button {
		background-color: $red;
		-moz-appearance: inherit;
		-webkit-appearance: inherit;
		border: 0;
		border-radius: 5px;
		color: #fff;
		text-transform: uppercase;
		font-size: .8em;
		line-height: 1.5em;
		padding: .5em 1em .3em;

		@include breakpoint($tab) {
			font-size: 1em;
			padding: .6em 2em .4em;
		}
	}

	a.button:hover {
		box-shadow: 0 0 1em #565656;
		color: #f1f0f0;
	}

	h2 {
		margin: .5em 0;
		text-align: center;
	}

	.node .field-name-body h2 {
		text-align: left;
	}

	h2 ~ p {
		margin-top: 0;
	}

	p.centered {
		text-align: center;
	}

	img {
		max-width: 100%;
	}

	.layout-3col__full {
		padding: 0;
	}

	.layout-3col__full > * {
		@include breakpoint($tab) {
			width: 100%;
			max-width: $max-content-width;
			margin-right: auto; // This should be left like this (declared precisely) since there may be a variation in margin-top and margin-bottom.
			margin-left: auto; // This should be left like this (declared precisely) since there may be a variation in margin-top and margin-bottom.
			padding-right: 2em;
			padding-left: 2em;
		}
	}

	.layout-center {
		// max-width: $max-content-width;
		max-width: 100%;
		padding: 0;
	}

	.layout-center > .layout-3col {
		max-width: 100%;
		margin: 0;
		padding: 0;
		min-height: 100vh;
		min-height: calc(100vh - 13em);
	}

	.layout-swap__top {
		position: static;
	}
}

.underline {
	text-decoration: underline;
}






// COLORBOX SYTLES

/* -------------------

CTY April 2017 - These are styles for the "fullscreen/touchfriendly" colorbox style. Justin was wanting something down this line so I came up with this.


You need to install the Colorbox module like normal and then also install the "Colorbox Swipe Gestures Support" module. ( https://www.drupal.org/project/colorbox_swipe ) Make sure to install the different libraries that those modules require.


Then, enter the following in the theme's script.js file where it says to enter your code.

      $('#cboxLoadedContent').click(function (ctyscustomclickcheck) {
        if (!$('#cboxLoadedContent img').is(ctyscustomclickcheck.target)) {
          $.colorbox.close();
        }
      });


Remember that you need to include (uncomment) that script.js file in the theme's info file..


After this, go to the Colorbox settings page on your site and make sure to use the following settings:

Style: Example 3
Options: Custom
Current: "{current} of {total} - Tap to see next image."  - Note: (Without quotes) Justin suggested adding the Tap to see next image.
Overlay close: checked
The "Max width", "Max height", "Initial width", and "Initial height" fields should all have: 100%
Fixed: checked
Scrollbars: On
Under Advanced Settings:
Mobile detection: Off  --NOTICE! THIS IS VERY IMPORTANT!
Caption shortening: probably would be good to use Default


And, make sure to use the following CSS styles for the Colorbox! ;)

---------------------- */

#colorbox,
#cboxWrapper,
#cboxContent,
#cboxLoadedContent {
	width: 100% !important;
	height: 100% !important;
	width: 100vw !important;
	height: 100vh !important;
	max-width: 100% !important;
	max-height: 100% !important;
	// max-width: 100vw !important;  CTY - Commenting this line out so that it uses the max-width: 100%; instead. This is so that the Colorbox controls/buttons don't go under the scrollbar in browsers where there's a scroll bar. The vw seems to include the area of the scrollbar while % doesn't seem to do that.
	max-height: 100vh !important;
	background: none;
}

#colorbox {
	top: 0 !important;
	left: 0 !important;
}

#cboxContent {
	margin-top: 0;
}

#cboxLoadedContent {
	display: -webkit-flex !important;
	display: flex !important;
	-webkit-box-align: center;
	align-items: center;
	border: 0;

	img {
		width: auto !important;
		height: auto !important;
		max-width: 100% !important;
		max-height: 100% !important;
		// max-width: 100vw !important;  CTY - Commenting this line out so that it uses the max-width: 100%; instead. This is so that the Colorbox images don't go under the scrollbar in browsers where there's a scroll bar. The vw seems to include the area of the scrollbar while % doesn't seem to do that.
		max-height: 100vh !important;
		border-radius: 0 !important;
		display: inline-block !important;
		margin: auto !important;
	}

	> div {
		width: 100% !important;
		height: 100% !important;
	}
}

#cboxTitle {
	top: auto !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    text-align: center;
    background-color: rgba(0, 0, 0, .5);
    font-size: .8em;
    line-height: 1.3em;
    padding: .5em;
    color: #fff;
}

#cboxTitle:empty {
	padding: 0;
}

#cboxCurrent {
	left: 0 !important;
	top: 0 !important;
	right: auto !important;
	bottom: auto !important;
	padding-left: .5em;
	text-shadow: 0 0 6px #000;
    color: #fff;
}

#cboxPrevious,
#cboxNext {
	outline: none;

	@include breakpoint(800px) {
		background-image: none;
    	width: 80px;
    	height: 130px;
    	margin-top: -65px;
    	background-color: rgba(0, 0, 0, .3);
	}
}

#cboxPrevious {
	left: 0 !important;
	@include breakpoint(800px) {
		border-radius: 0 20px 20px 0;
	}
}

#cboxNext {
	right: 0 !important;
	@include breakpoint(800px) {
		border-radius: 20px 0 0 20px;
	}
}

#cboxPrevious:hover,
#cboxNext:hover {
	@include breakpoint(800px) {
		background-color: rgba(0, 0, 0, .5);
	}
}

#cboxPrevious::after,
#cboxNext::after {
	@include breakpoint(800px) {
		content: '';
		display: block;
		width: 50px;
		height: 50px;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		border-radius: 5px;
	}
}

#cboxPrevious::after {
	@include breakpoint(800px) {
		border-top: solid 5px #fff;
		border-left: solid 5px #fff;
		-ms-transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg);
		margin-right: 0;
		box-shadow: -3px -3px 1px #000;
	}
}

#cboxNext::after {
	@include breakpoint(800px) {
		border-top: solid 5px #fff;
		border-right: solid 5px #fff;
		-ms-transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
		margin-left: 0;
		box-shadow: 3px -3px 1px #000;
	}
}

#cboxClose {
	top: 0 !important;
	right: 0 !important;
	outline: none;
	@include breakpoint(800px) {
		background-image: none;
		background-color: rgba(0, 0, 0, .3);
		width: 50px;
		height: 50px;
		border-radius: 30px 0 30px 30px;
	}
}

#cboxClose:hover {
	@include breakpoint(800px) {
		background-color: rgba(0, 0, 0, .5);
	}
}

#cboxClose::after {
	@include breakpoint(800px) {
		content: 'X';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		color: #fff;
		text-indent: 0;
		font-family: Helvetica, Arial, Verdana, Tahoma, 'DejaVu Sans', sans-serif;
		font-size: 30px;
		padding-top: 10px;
		text-shadow: 0 0 5px #000;
	}
}

/* ------------------- CTY - The styles above are for the "fullscreen/touchfriendly" colorbox style. (See notes above these styles.) Any other custom styles should go below this.. Thanks! */






// HEADER STYLES GO HERE
.header {
	position: relative;
	border-bottom: solid 15px $red;
	background-color: $light-gray;
	min-height: 10em;
}

.header__logo {
	margin: 2em;
	max-width: 500px;
	z-index: 10;
	position: relative;

	@include breakpoint(769px) {
		width: 40%;
		margin-bottom: 0;
	}

	@include breakpoint(1200px) { // This breakpoint should be the same as the max-width you want for the header.
		position: absolute;
		top: 0;
		left: calc((100% - 1200px) / 2); // The fixed pixel value here should be the same as the max-width you want for the header.
	}
}

.header__secondary-menu {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	float: none;
	width: 100%;
	max-width: 1200px;
	margin: auto;
	padding-left: 1em;
	text-align: right;
	font-size: .9em;
	font-weight: bold;

	ul {
		margin: .5em 0;
	}
}

.header__region {
	@include breakpoint(769px) {
		clear: none;
		max-width: 1200px; // Making the content in the header be a little wider than the default max-width for the rest of the content on the page.
		margin: 3.5em auto 0;
		padding: 0 1em;
		text-align: right;
	}
}

#block-block-14 {
	padding: 0 2em;
	margin-bottom: 2em;

	@include breakpoint(350px) {
		margin-bottom: 0;
	}

	p {
		margin: .5em 0;
	}
}

#block-menu-block-1 {
	position: absolute;
	bottom: 0;
	right: 1em;

	@include breakpoint(769px) {
		position: static;
		float: right;
	}

	.responsive-menus.responsified span.toggler {
		display: inline-block;
		width: auto;
		box-shadow: none;
		padding: 0 1em;
		color: $red;
		background-color: $half-light-gray;
		position: relative;
		top: 2px;
		border-radius: 5px 5px 0 0;
	}

	.responsive-menus.responsified .responsive-menus-simple {
		right: -1em;
		width: 12em;
		background-color: $half-light-gray;
		box-shadow: none;

		a {
			color: $red;
			text-transform: none;
		}

		a:hover {
			background-color: #949494;
		}
	}

	ul.menu {
		background-color: $half-light-gray;
		border-radius: 5px;
		padding: .3em 2em .2em;
		margin: 1em 0;
		font-size: .9em;
	}

	ul.menu li {
		list-style: none;
		display: inline-block;
		vertical-align: top;
		margin-left: .5em;
	}

	ul.menu li.first {
		margin-left: 0;
	}

	ul.menu li::after {
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: 1px;
		height: .8em;
		background-color: $red;
		margin-left: .8em;
		margin-bottom: .2em;
	}

	ul.menu li.last::after {
		display: none;
	}
}






// MAIN COTENT SYTLES GO HERE
.region-highlighted {
	max-width: 100% !important;
	position: relative;
	padding: 0 !important;
}

.node {
	padding: 0 2em;
}

.node .field-name-body {
	.thumbnail-sample,
	p.image {
		float: left;
	    width: 26%;
		margin: 0 5% 4% 0;
		display: none;

		@include breakpoint($tab) {
			display: block;
		}

		img {
			border: solid 2px #000;
		}
	}

	.thumbnail-sample ~ *,
	p.image ~ * {
		@include breakpoint($desk) {
			width: 69%;
			margin-left: auto;
		}
	}

	p.buttons a.button {
		margin: 1em;

		@include breakpoint($tab) {
			margin: 1em 2em;
		}
	}
}


#block-block-8 {
	position: absolute;
	bottom: -10px;
	right: 0;
	left: 0;
	max-width: $max-content-width;
	margin: auto;
	text-align: right;
	padding: 0 .5em;

	p {
		margin: 0;
	}

	.button {
		@include breakpoint($desk) {
			font-size: 1.3em;
		}
	}
}

h1 {
    margin: 1.4em 0 .3em;
    color: $light-black;
    text-transform: uppercase;
    padding: 0 1em !important;

	@include breakpoint($desk) {
		font-size: 3em;
		padding: 0 !important;
	}
}



#block-block-11 {
	color: #fff;
	background-color: $red;
	padding: 1em 2em;
	font-weight: 100;

	@include breakpoint($tab) {
		float: left;
		width: 33%;
		max-width: 100%;
		padding: 3%;
		min-height: 52vw;
	}

	@include breakpoint($max-content-width) {
		min-height: 38vw;
	}

	@include breakpoint(1300px) {
		min-height: 32vw;
	}

	h2 {
		text-align: left;

		@include breakpoint($tab) {
			font-size: 3vw;
			line-height: 4vw;
			margin: 0 0 2vw;
		}

		@include breakpoint($max-content-width) {
			font-size: 2.3vw;
			line-height: 3vw;
		}
	}

	p {
		@include breakpoint($tab) {
			font-size: 2vw;
			line-height: 2.8vw;
			margin: 0;
		}

		@include breakpoint($max-content-width) {
			font-size: 1.5vw;
			line-height: 2.5vw;
		}

		@include breakpoint(1300px) {
			font-size: 1.3vw;
			line-height: 2.2vw;
		}
	}
}

#block-quicktabs-mission-points {
	background-color: $light-gray;
	padding: 0;

	@include breakpoint($tab) {
		float: left;
		width: 67%;
		max-width: 100%;
		min-height: 52vw;
	}

	@include breakpoint($max-content-width) {
		min-height: 38vw;
	}

	@include breakpoint(1300px) {
		min-height: 32vw;
	}

	.quicktabs-tabs {
		padding: 0;
		margin: 0;
		display: -webkit-box;
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		text-align: center;
		font-size: 3.5vw;
		line-height: 5vw;
		font-weight: bold;

		@include breakpoint($tab) {
			font-size: 2vw;
			line-height: 3.4vw;
		}

		> li {
			white-space: inherit;
			padding: 0;
			position: relative;
			width: 25%;
			background-color: $gray;
			background-position: center center; // Background images are added below.
			background-size: cover;
			background-repeat: no-repeat;
		}

		> li::before {
			content: '';
			display: block;
			padding-top: 100%;
		}

		> li:hover {
			background-color: #797777;
		}

		> li.active {
			background-color: $dark-gray;
		}

		> li.active::after { // This is for the arrow on the active one.
			content: '';
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			width: 30px;
			height: 30px;
			margin: auto;
			border: solid 15px transparent;
			border-bottom: solid 15px $light-gray;
			box-sizing: border-box;
		}

		> li:nth-child(1) {
			background-image: url('/sites/default/files/custom-blocks/mission-points-block/plowing.jpg');
		}

		> li:nth-child(2) {
			background-image: url('/sites/default/files/custom-blocks/mission-points-block/furniture.jpg');
		}

		> li:nth-child(3) {
			background-image: url('/sites/default/files/custom-blocks/mission-points-block/welding.jpg');
		}

		> li:nth-child(4) {
			background-image: url('/sites/default/files/custom-blocks/mission-points-block/mechanics.jpg');
		}

		a {
			color: #fff;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			display: -webkit-box;
			display: flex;
			-webkit-box-align: center;
			align-items: center;
			padding: 5%;
		}
	}

	.quicktabs-tabpage {
		padding: 2em;
		font-weight: bold;
		font-size: .8em;

		@include breakpoint($tab) {
			font-size: 1.8vw;
			line-height: 3.7vw;
			padding: 6%;
		}

		@include breakpoint($max-content-width) {
			font-size: 1.4vw;
			line-height: 2.4vw;
			padding: 3% 6%;
		}

		@include breakpoint(1300px) {
			font-size: 1.1vw;
			line-height: 2vw;
		}
	}

	.quicktabs-tabpage p {
		margin: 0;

		@include breakpoint($tab) {
			margin: 2vw 0;
		}

		@include breakpoint(1300px) {
			margin: 1vw 0;
		}
	}
}

#block-quicktabs-mission-points + * { // This selects the adjacent sibling.
	clear: both; // I'm adding this since the #block-quicktabs-mission-points block is set to float.
}

#block-block-12 {
	text-align: center;
	padding-top: 1px; // This is here for a reason..
	display: none;

	@include breakpoint($tab) {
		display: block;
	}

	img {
		display: inline-block;
		width: 11%;
		margin: 2%;
		border-radius: 100%;
		border: solid 1px $light-black;
	}
}

#block-block-9 {
	max-width: 100%;
	padding-right: 0;
	padding-left: 0;
	background-color: $dark-gray;
	padding: 2em;
	color: #fff;
	font-weight: 300;

	.block__title {
		text-transform: uppercase;
		font-size: 2em;
	}

	.content-container {
		max-width: $max-content-width;
		margin: auto;
	}

	a {
		color: #fff;
	}
}

#block-block-13 {
	max-width: 100%;
	padding-right: 0;
	padding-left: 0;

	@include breakpoint($tab) {
		display: -webkit-box;
		display: flex;
	}

	.stats,
	.we-focus-on {
		@include breakpoint($tab) {
			width: 50%;
		}
	}

	.stats {
		color: #fff;
		background-color: $red;
		padding: 1em 2em;
		text-align: center;
	}

	.stats p {
		@include breakpoint(1100px) {
			width: calc(1100px / 2); // The fixed pixel value should be set the same as the $max-content-width variable.
			margin-left: auto;
		}
	}

	.stats .larger {
		font-size: 3em;
		line-height: 1em;
		font-weight: bold;

		@include breakpoint($tab) {
			font-size: 4em;
		}
	}

	.we-focus-on {
		color: $red;
		background-color: $light-gray;
		padding: 1em 2em 2em;

		h3 {
			margin: 1em 0 .5em;
		}

		ul {
			padding-left: 3em;
			margin: 0;
		}
	}
}


// Styles for the /advertise page below
.page-node-7 .node .field-name-body p.image {
	display: block;
	width: 45%;
	margin: 0 7% 6% 0;

	@include breakpoint($tab) {
		width: 26%;
		margin: 0 5% 4% 0;
	}
}


// Styles for the /subscribe page below
.page-node-8 .node .field-name-body {
	text-align: center;
}

#block-views-uc-products-block-1 {
	background-color: $half-light-gray;
    font-size: .85em;
    font-weight: bold;
    color: $red;
    padding-right: 0;
    padding-left: 0;

	@include breakpoint($tab) {
		font-size: 1em;
	}

	@include breakpoint($desk) {
		font-size: 1.4em;
	}

	table {
		margin: 0;
	}

	table td {
		border-bottom: solid 2px #fff;
		border-right: solid 2px #fff;
		padding: 1em 0;

		@include breakpoint($tab) {
			border-width: 4px;
		}
	}

	table td:last-of-type {
		border-right: 0;
	}

	tr:last-of-type td {
		border-bottom: 0;
	}

	table input[type='submit'] {
		background-color: $red;
		border: 0;
		border-radius: 2em;
		color: #fff;
		text-transform: uppercase;
		font-size: .8em;
		line-height: 1.5em;
		padding: .5em 1em .3em;

		@include breakpoint($tab) {
			font-size: 1em;
			padding: .6em 2em .4em;
			border-radius: 10px;
		}
	}

	table input[type='submit']:hover {
		box-shadow: 0 0 1em #565656;
		color: #f1f0f0;
	}
}


// Styles for the /contact page below
#block-block-21 {
	padding: 0 2em;
	margin-bottom: 3em;

	@include breakpoint($tab) {
		float: right;
		width: 50%;
	}

	@include breakpoint($max-content-width) {
		max-width: 550px;
		margin-right: calc((100% - 1100px) / 2); // The fixed pixel value should be set the same as the $max-content-width variable.
	}
}

#block-webform-client-block-1 {
	padding: 0 2em;

	@include breakpoint($tab) {
		float: right;
		width: 50%;
		max-width: 550px;
	}

	#webform-client-form-1 {
		max-width: 550px;
	}

	.form-item {
		margin: 1em 0;
	}

	label {
		font-weight: normal;
		font-size: .9em;
	}

	input,
	textarea {
		width: 100%;
		background-color: $half-light-gray;
		border: 0;
		padding-left: .5em;
	}

	#webform-component-postal-code input {
		width: 10em;
	}

	.form-actions input {
		background-color: #a11300;
		border: 0;
		border-radius: 2em;
		color: #fff;
		text-transform: uppercase;
		font-size: .8em;
		line-height: 1.5em;
		padding: .5em 2em .3em;
		width: auto;

		@include breakpoint($tab) {
			font-size: 1em;
			padding: .6em 2em .4em;
		}
	}

	.form-actions input:hover {
		box-shadow: 0 0 1em #565656;
		color: #f1f0f0;
	}
}


// Styles for cart/checkout/etc pages below
.section-cart,
.section-checkout {
	h1 {
		text-align: center;
	}
}

#uc-cart-view-form,
.uc-cart-checkout-form {
	font-size: .9em;

	fieldset {
		margin-bottom: 1em;
	}

	.fieldset-legend {
		color: $red;
		font-weight: bold;
	}

	table.cart-review {
		thead {
			background-color: $red;
		}

		th {
			border-right: solid 2px #fff;
			border-bottom: solid 2px #fff;
			padding-left: .5em;
			text-transform: uppercase;
			color: #fff;
			font-weight: normal;
		}

		td {
			border-right: solid 2px #fff;
			border-bottom: solid 2px #fff;
			padding-left: .5em;
			background-color: $half-light-gray;
			font-weight: bold;
		}
	}

	.uc-store-address-field .form-item label {
		text-align: left;
		padding-bottom: 0;
		width: 100%;

		@include breakpoint(560px) {
			width: 15em;
			text-align: right;
			padding: 5px 6px 6px;
		}
	}

	input,
	textarea {
		border: 0;
		background-color: $half-light-gray;
		padding-left: .5em;
	}

	.form-actions {
		margin-top: 1em;
	}

	#edit-update,
	#edit-cancel {
		margin-left: 1em;
		padding: .3em 2em;
	}

	#edit-checkout--2,
	#edit-continue {
		margin-left: 1em;
		background-color: $red;
		color: #fff;
		padding: .3em 2em;
	}
}

.page-cart-checkout-review #review-instructions {
	padding: 0 1em;
}

.page-cart-checkout-review .order-review-table {
	width: 96%;

	tr.pane-title-row {
		background-color: $red;
		color: #fff;
		font-weight: normal;
		text-transform: uppercase;
	}

	table.cart-review {
		width: 96%;
		margin-left: auto;
		margin-right: auto;
	}

	.review-button-row {
		background-color: transparent;

		#edit-back,
		#edit-submit {
			margin: 1em;
			padding: .3em 2em;
			border: 0;
		}

		#edit-submit {
			background-color: $red;
			color: #fff;
		}
	}
}

#block-block-20 {
	margin-bottom: 5em;
	width: 100%;
	max-width: $max-content-width;
	margin-right: auto; // This should be left like this (declared precisely) since there may be a variation in margin-top and margin-bottom.
	margin-left: auto; // This should be left like this (declared precisely) since there may be a variation in margin-top and margin-bottom.
	padding-right: 2em;
	padding-left: 2em;
	text-align: left;
	font-size: .9em;
	line-height: 1.6em;
}


// Styles for the Product pages below
.node-type-product .node {
	.add-to-cart input[type='submit'] {
		background-color: $red;
		border: 0;
		border-radius: 2em;
		color: #fff;
		text-transform: uppercase;
		font-size: .8em;
		line-height: 1.5em;
		padding: .5em 1em .3em;

		@include breakpoint($tab) {
			font-size: 1em;
			padding: .6em 2em .4em;
			border-radius: 10px;
		}
	}

	.add-to-cart input[type='submit']:hover {
		box-shadow: 0 0 1em #565656;
		color: #f1f0f0;
	}
}






// FOOTER STYLES GO HERE
.region-footer {
	text-align: center;
}

#block-footer-message-footer-message {
	width: 100%;
	max-width: $max-content-width;
	margin-right: auto; // This should be left like this (declared precisely) since there may be a variation in margin-top and margin-bottom.
	margin-left: auto; // This should be left like this (declared precisely) since there may be a variation in margin-top and margin-bottom.
	padding-right: 2em;
	padding-left: 2em;
	border-bottom: solid 10px $red;

	p {
		margin-bottom: .5em;
	}
}

#block-menu-block-2,
#block-menu-block-3 {
	text-align: center;
	font-weight: bold;

	@include breakpoint($tab) {
		display: inline-block;
		vertical-align: top;
		margin: 0 1em;
	}

	ul.menu {
		padding: .3em 2em .2em;
		margin: .3em 0 1em;
		font-size: .9em;
		text-transform: uppercase;

		a {
			color: #000;
		}
	}

	ul.menu li {
		list-style: none;
		display: inline-block;
		vertical-align: top;
		margin-left: .5em;
	}

	ul.menu li.first {
		margin-left: 0;
	}

	ul.menu li::after {
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: 1px;
		height: .8em;
		background-color: #000;
		margin-left: .8em;
		margin-bottom: .2em;
	}

	ul.menu li.last::after {
		display: none;
	}
}

#block-menu-block-2 {
	display: none;

	@include breakpoint($tab) {
		display: inline-block;
	}

	@include breakpoint($desk) {
		margin-right: 5em;
	}
}

#block-menu-block-3 {
	@include breakpoint($desk) {
		margin-left: 5em;
	}
}
